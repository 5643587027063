*{
  margin: 0;
  padding: 0;
  font-family: 'Poppins',sans-serif;
  color: #FFFFFF;
  
}
html{
  background-color: #00040F;
  overflow: overlay;
  overflow-x: hidden;
  /* max-width: 1540px; */
  position: relative;
}
::-webkit-scrollbar{
  width: 18px;
  border-radius: 10px;
  background-color: transparent;
}
::-webkit-scrollbar-track {
  display: none;
}
::-webkit-scrollbar-thumb {
  background: rgba(0,4,15,.8); 
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #00040F; 
}
@media screen and (max-width: 780px) {
  ::-webkit-scrollbar{
    display: flex;
  }
  ::-webkit-scrollbar-thumb {
      display: none;
  }
}


