.nav-cont{
    width: 100vw;
    padding: 32px 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    background-color: rgba(0,4,15,.8);
    backdrop-filter:blur(12px);
    box-shadow: 1px 0px 76px 10px rgba(0,4,15,0.75);
    -webkit-box-shadow: 1px 0px 76px 10px rgba(0,4,15,0.75);
    -moz-box-shadow: 1px 0px 76px 10px rgba(0,4,15,0.75);
    z-index: 100;
}
.nav-logo{
    width: 96px;
    padding: 0 8%;
}
.list-open, .list-close{
    display: flex;
    list-style: none;
    width: 30%;
    min-width: 320px;
    justify-content: space-between;
    padding: 0 8%;
}
li:hover{
    color: var(--main-color);
    cursor: pointer;
}
.list-icon{
    margin-right: 8%;
    display: none;
    width: 22px;
    height: 22px;
    cursor: pointer;
    fill: rgb(90, 249, 255);
}

.list-icon:hover{
    fill: rgba(49, 248, 255, 0.7);

}
@media screen and (max-width:900px) {
    .list-icon{
        display: block;
    }
    .list-close{
        display: none;
    }
    .list-open{
        display: block;
        position: fixed;
        top: 89.6px;
        right: 0;
        width: 40%;
        min-width:fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgba(0,4,15,.9);
        padding: 16px;
        padding-bottom: 3rem;
        animation-name: slideron;
        animation-duration: 1.5s;
        overflow: hidden;
        box-shadow: 0px 1px 50px 1px rgba(16,244,252,0.1);
        -webkit-box-shadow: 0px 1px 50px 1px rgba(16,244,252,0.1);
        -moz-box-shadow: 0px 1px 50px 1px rgba(16,244,252,0.1);
    }
    @keyframes slideron {
        0%   {height: 0; opacity: 0;}
        100% {height: 288px;opacity: 1;}
        
    } 
    li{
        padding:  40px 16px 8px 16px;
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow: visible;
        font-size: 2rem;
    }
    li:hover{
        padding-bottom: 4.8px;
        border-bottom: 3.2px solid  rgba(16, 244, 252, 0.7) ;
        animation-name: underliner;
        animation-duration: .5s;
    }
    @keyframes underliner {
        0%   {width: 16px;}
        100% {width: 80px }
    } 
}