html{
  overflow-x: hidden;
}
body{
  overflow-x: hidden;
  margin-top: 5rem;
}
:root{
  --mainwidth:85%;
  --secondarywidth:83%;
  --maincolor:#00F6FF;
  --maingradient:radial-gradient(64.18% 64.18% at 71.16% 35.69%, #DEF9FA 0.89%, #BEF3F5 17.23%, #9DEDF0 42.04%, #7DE7EB 55.12%, #5CE1E6 71.54%, #33BBCF 100%);
  --btn-gradient: linear-gradient(157.81deg, #DEF9FA -43.27%, #BEF3F5 -21.24%, #9DEDF0 12.19%, #7DE7EB 29.82%, #5CE1E6 51.94%, #33BBCF 90.29%);
  --ballgradient: radial-gradient(64.18% 64.18% at 71.16% 35.69%, #DEF9FA 0.89%, #BEF3F5 17.23%, #9DEDF0 42.04%, #7DE7EB 55.12%, #5CE1E6 71.54%, #33BBCF 100%);
  --secondary-gradient:linear-gradient(153.47deg, rgba(179, 177, 177, 0) -341.94%, #14101D 95.11%);
  --divgradient:linear-gradient(180deg, rgba(60, 60, 60, 0.4) 0%, rgba(180,180,180,.2) 35%, rgba(92,92,92,.3) 100%);
  --cardgradient:linear-gradient(144.39deg, #FFFFFF -278.56%, #6D6D6D -78.47%, #11101D 91.61%);
  --cardgradient:linear-gradient(195.39deg, #787878 -278.56%, #6D6D6D -78.47%, #11101D 91.61%);
}
.main-title{
  font-size: 2rem;
  font-weight: 900;
  line-height: 5rem;
  word-spacing: .2rem;
  letter-spacing: .2rem;
}
.grad{
  background: radial-gradient(64.18% 64.18% at 71.16% 35.69%, #DEF9FA 0.89%, #BEF3F5 17.23%, #9DEDF0 42.04%, #7DE7EB 55.12%, #5CE1E6 71.54%, #33BBCF 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.large{
  font-size: 4.8rem;
  font-weight: 900;
}
.medium{
  font-size: 3.2rem;
  font-weight:900;
  text-shadow: 2px 0 #ffffff;
}
.small{
  font-size: 3rem;
  font-weight:900;
  text-shadow: 2px 0 #ffffff;
}
.white{
  color: #ffffff;
}
.main-pargraph{
  width: 75%;
  line-height:2.1rem;
  font-size: 1.3rem;
  }
.secondary-pargraph{
  width: 100%;
  line-height:2.1rem;
  font-size: 1.2rem;
}
.gray{
  color: rgba(255, 255, 255,.7);
}
.gray2{
  color: rgba(255, 255, 255,.4);
  fill: rgba(255, 255, 255,.4);
}
.wider{
  width: 85%;
}
button{
  background: var(--btn-gradient);
  color: #00040F;
  border-radius: 10px;
  padding:1rem 2rem;
  font-size: 1.2rem;
  font-weight:500;
  width: fit-content;
  cursor: pointer;
}
.blur{
  width: 30rem;
  height: 30rem;
	background: rgba(0, 246, 255, .3);
	position: absolute;
	filter: blur(150px);
	z-index: -10;
}
.circle-blur{
  border-radius: 50%;
  
}
.hero-l-blur{
  left: 0;
}
.hero-r-blur{
  width: 32rem;
  height: 32rem;
	background: rgba(0, 246, 255, .28);
  top: 10rem;
  right: 5rem;
  filter: blur(100px);
}
.line-blur{
  width: 50rem;
  height: 15rem;
  background: rgba(210, 134, 251, 0.25);
  /* background: rgba(0, 246, 255, 0.35); */
  top: 15rem;
  left: -15rem;
	filter: blur(120px);
}
.aboutus-blur{
  width: 50rem;
  height: 50rem;
  background: rgba(0, 246, 255, .20);
  right: -20rem;
  top: 6rem;
}
.footer-blur{
  width: 100%;
  height: 14rem;
  /* background: rgba(0, 246, 255, .3); */
  background: rgba(210, 134, 251, 0.3);
  bottom: 0;
}
@media screen and (max-width:900px) {
  html{
    font-size: 8px;
    width: 100vw;
  }
  .large{
    font-size: 5rem;
  }
  .medium{
    font-size: 4rem;
    font-weight:900;
    text-shadow: 2px 0 #ffffff;
  }
  .small{
    font-size: 2.7rem;
    font-weight:900;
    text-shadow: 2px 0 #ffffff;
  }
  .main-pargraph{
    width: 90%;
    line-height:3rem;
    font-size: 1.9rem;
    }
    .secondary-pargraph{
    width: 90%;
    line-height:3rem;
    font-size: 1.9rem;
}
    button{
      padding:1.5rem 3rem;
      font-size: 1.8rem;
    }
}
@media screen and (max-width:400px) {
  .main-title{
    font-size: 3.2rem;
  }
  .main-pargraph{
    width: 90%;
    line-height:2.1rem;
    font-size: 1.6rem;
    }
    .secondary-pargraph{
      width: 90%;
      line-height:2.1rem;
      font-size: 1.6rem;
  }
  .small{
    font-size: 2rem;
    font-weight: 700;
  }
  .medium{
    font-size: 2.7rem;
  }
}


