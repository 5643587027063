.start-cont{
    width: var(--mainwidth);
    margin: auto;
    margin-top: 10rem;
}
.platforms{
    width: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10rem;
}
.platforms>img{
    width: 14rem;
    opacity: .5;
}
.platforms>img:hover{
    opacity: 1;
    cursor: pointer;
}
.start-now{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--cardgradient);
    padding: 4rem 6rem;
    border-radius: 20px;
}
.start-now>:nth-child(1){
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.start-now>:nth-child(1)>p{
    width: 60%;
}
    @media screen and (max-width:900px) {
        .platforms{
            flex-direction: column;
            gap: 5rem;
        }
        .platforms>img{
            width: 25rem;
            opacity: .5;
        }
        .start-now{
            flex-direction: column;
            align-items: flex-start;
            gap: 5rem;
            padding: 4rem 5rem;
        }
        .start-now>:nth-child(1){
            width: 100%;
        }
        .start-now>:nth-child(1)>p{
            width: 100%;
        }
        .start-p{
            font-size: 1.5rem;
        }

    }
    @media screen and (max-width:400px) {

    }