.billing-cont{
    width: var(--mainwidth);
    margin: auto;
    margin-top: 10rem;
    display: flex;
    justify-content: space-between;
}
.l-s-billing-cont{
    width: 55%;
    height: 35rem;
    position: relative;
    display: flex;
    flex-direction: column;
}
.bill-payment{
    border-radius: 10px;
    position: absolute;
}
.billing-payment1{
    width: 17rem;
    top: 0;
    left: 17rem;
    z-index: 5;
}
.billing-payment2{
    width: 24rem;
    top: 12rem;
}
.cheack-payment{
    align-items: center;
    display: flex;
    gap: 1rem;
    background-color: #ffffff;
    border-radius: 10px;
    width: fit-content;
    padding: .8rem 2rem;
    position: absolute;
    top: 30rem;
    left: 12rem;
    z-index: 5;
}
.cheack-payment>span{
    color: #00040F;
    font-weight: 500;
}
.billing-check{
    width: 2rem;
}
.r-s-billing-cont{
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.bill-stores-cont{
    display: flex;
    flex-direction: row;
    gap: 2rem;
}
.bill-store{
    width: 10rem;
    border-radius: 10px;
}
.bill-store:hover{
    box-shadow: 1px 8px 142px 15px rgba(0,246,255,0.34);
    -webkit-box-shadow: 1px 8px 142px 15px rgba(0,246,255,0.34);
    -moz-box-shadow: 1px 8px 142px 15px rgba(0,246,255,0.34);
}
    @media screen and (max-width:900px) {
        .billing-cont{
            flex-direction: column-reverse;
            gap: 10rem;
        }
        .l-s-billing-cont{
            width: 100%;
            height: 62vw;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .r-s-billing-cont{
            width: 100%;
        }
        .billing-payment1{
            width: 35vw;
            top: 0;
            left: 45%;
            z-index: 5;
        }
        .billing-payment2{
            width: 42vw;
            top: 25vw;
            left: 10%;
        }
        .cheack-payment{
            font-size: 2.5vw;
            top : 58vw;
            left: 35%;
        }
        .bill-stores-cont{
            width: 100%;
            align-items: center;
            justify-content: center;
        }
        .bill-store{
            width: 50%;
        }
    }
    @media screen and (max-width:400px) {
        .billing-payment1{
            top: 0;
            width: 50%;
        }
        .billing-payment2{
            top: 12rem;
            width: 60%;
            left: 0;
        }
        .cheack-payment{
            font-size: .9rem;
            padding: .5rem 1rem;
            border-radius: 5px;
            top : 63.5vw;
        }
    }