.findcard-cont{
    width: var(--mainwidth);
    margin: auto;
    margin-top: 10rem;
    display: flex;
    justify-content: space-between;
}
.l-s-findcard-cont{
    width: 55%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.findcard-header{
    width: 90%;
}
.r-s-findcard-cont{
    width: 40%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.findcard-circle{
    border-radius: 50%;
    background: linear-gradient(45deg,rgba(26, 186, 192, 0.3),rgba(189, 253, 255, 0.3)) border-box; /*3*/
    -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude; 
    position: absolute;
}
.circle1{
    width: 381px;
    height: 381px;
    border: .6rem solid transparent;
    transform: rotate(140deg);
}
.circle2{
    width: 253.29px;
    height: 253.29px;
    border: .4rem solid transparent;
    transform: rotate(100deg);
}
.circle3{
    width: 124.63px;
    height: 124.63px;
    border: .2rem solid transparent;
    transform: rotate(50deg);
}
.findcard-images{
    border-radius: 20px;
    z-index: 5;
}
.findcard-images-cont{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: fit-content;
    gap: 5rem;
}
.findcard-images-cont>div{
    display: flex;
    gap: 25%;
    align-items: center;
    justify-content: center;
    margin-left: 2rem;
}
.findcard-image1{
    height: 8rem;
}
.findcard-image2{
    height: 15rem;
}
.findcard-image3{
    height: 11rem;
}
    @media screen and (max-width:900px) {
        .findcard-cont{
            flex-direction: column;
            gap: 10rem;
        }
        .l-s-findcard-cont{
            width: 100%;
        }
        .r-s-findcard-cont{
            width: 100%;
            height: 53rem;
        }
        .findcard-images-cont{
            gap: 12vmax;
            
        }
        .circle1{
            width: 304.8px;
            height: 304.8px;
        }
        .circle2{
            width: 202.632px;
            height: 202.632px;
        }
        .circle3{
            width: 99.704px;
            height: 99.704px;
        }
        .findcard-image1{
            height: 10vw;
            min-height: 10rem;
        }
        .findcard-image2{
            height: 25vw;
            min-height: 20rem;
        }
        .findcard-image3{
            height: 18vw;
            min-height: 14rem;
        }
    }
    @media screen and (max-width:400px) {
        .circle1{
            width: 228.6px;
            height: 228.6px;
        }
        .circle2{
            width: 151.974px;
            height: 151.974px;
        }
        .circle3{
            width: 74.778px;
            height: 74.778px;
        }
        .findcard-image1{
            min-height: 8rem;
        }
        .findcard-image2{
            min-height: 16rem;
        }
        .findcard-image3{
            min-height: 12rem;
        }
    }